export const environment = {
  production: false,
  users: {
    // ENAVARRO: "https://app.powerbi.com/reportEmbed?reportId=be9f6255-0b40-42f7-90d7-a43547adfc2a&autoAuth=true&ctid=42924fb7-263c-4656-a029-09b26634a3cd&filter=Configuracion_Asesores%2Fasesor%20eq%20%27ENAVARRO%27",
    // EVASQUEZE: "https://app.powerbi.com/reportEmbed?reportId=be9f6255-0b40-42f7-90d7-a43547adfc2a&autoAuth=true&ctid=42924fb7-263c-4656-a029-09b26634a3cd&filter=Configuracion_Asesores%2Fasesor%20eq%20%27EVASQUEZE%27",
    // KMARTINEZ1: "https://app.powerbi.com/reportEmbed?reportId=be9f6255-0b40-42f7-90d7-a43547adfc2a&autoAuth=true&ctid=42924fb7-263c-4656-a029-09b26634a3cd&filter=Configuracion_Asesores%2Fasesor%20eq%20%27KMARTINEZ1%27",
    // DARENAS: "https://app.powerbi.com/reportEmbed?reportId=be9f6255-0b40-42f7-90d7-a43547adfc2a&autoAuth=true&ctid=42924fb7-263c-4656-a029-09b26634a3cd&filter=Configuracion_Asesores%2Fasesor%20eq%20%27DARENAS%27",
    // CPASTRANA: "https://app.powerbi.com/reportEmbed?reportId=be9f6255-0b40-42f7-90d7-a43547adfc2a&autoAuth=true&ctid=42924fb7-263c-4656-a029-09b26634a3cd&filter=Configuracion_Asesores%2Fasesor%20eq%20%27CPASTRANA%27",
    // EPENAM: "https://app.powerbi.com/reportEmbed?reportId=be9f6255-0b40-42f7-90d7-a43547adfc2a&autoAuth=true&ctid=42924fb7-263c-4656-a029-09b26634a3cd&filter=Configuracion_Asesores%2Fasesor%20eq%20%27EPENAM%27",
    // JARIVERA: "https://app.powerbi.com/reportEmbed?reportId=be9f6255-0b40-42f7-90d7-a43547adfc2a&autoAuth=true&ctid=42924fb7-263c-4656-a029-09b26634a3cd&filter=Configuracion_Asesores%2Fasesor%20eq%20%27JARIVERA%27",
    // JMVILLA: "https://app.powerbi.com/reportEmbed?reportId=be9f6255-0b40-42f7-90d7-a43547adfc2a&autoAuth=true&ctid=42924fb7-263c-4656-a029-09b26634a3cd&filter=Configuracion_Asesores%2Fasesor%20eq%20%27JMVILLA%27",
    // CLACOSTA: "https://app.powerbi.com/reportEmbed?reportId=be9f6255-0b40-42f7-90d7-a43547adfc2a&autoAuth=true&ctid=42924fb7-263c-4656-a029-09b26634a3cd&filter=Configuracion_Asesores%2Fasesor%20eq%20%27CLACOSTA%27",
    // JPALACIO: "https://app.powerbi.com/reportEmbed?reportId=be9f6255-0b40-42f7-90d7-a43547adfc2a&autoAuth=true&ctid=42924fb7-263c-4656-a029-09b26634a3cd&filter=Configuracion_Asesores%2Fasesor%20eq%20%27JPALACIO%27",
    // JVASQUEZ: "https://app.powerbi.com/reportEmbed?reportId=be9f6255-0b40-42f7-90d7-a43547adfc2a&autoAuth=true&ctid=42924fb7-263c-4656-a029-09b26634a3cd&filter=Configuracion_Asesores%2Fasesor%20eq%20%27JVASQUEZ%27",
    // LARRIETA: "https://app.powerbi.com/reportEmbed?reportId=be9f6255-0b40-42f7-90d7-a43547adfc2a&autoAuth=true&ctid=42924fb7-263c-4656-a029-09b26634a3cd&filter=Configuracion_Asesores%2Fasesor%20eq%20%27LARRIETA%27",
    // LELOPEZ: "https://app.powerbi.com/reportEmbed?reportId=be9f6255-0b40-42f7-90d7-a43547adfc2a&autoAuth=true&ctid=42924fb7-263c-4656-a029-09b26634a3cd&filter=Configuracion_Asesores%2Fasesor%20eq%20%27LELOPEZ%27",
    // RTAPIA: "https://app.powerbi.com/reportEmbed?reportId=be9f6255-0b40-42f7-90d7-a43547adfc2a&autoAuth=true&ctid=42924fb7-263c-4656-a029-09b26634a3cd&filter=Configuracion_Asesores%2Fasesor%20eq%20%27RTAPIA%27",
    // TROMULO: "https://app.powerbi.com/reportEmbed?reportId=be9f6255-0b40-42f7-90d7-a43547adfc2a&autoAuth=true&ctid=42924fb7-263c-4656-a029-09b26634a3cd&filter=Configuracion_Asesores%2Fasesor%20eq%20%27TROMULO%27",
    // ACORREA: "https://app.powerbi.com/reportEmbed?reportId=be9f6255-0b40-42f7-90d7-a43547adfc2a&autoAuth=true&ctid=42924fb7-263c-4656-a029-09b26634a3cd&filter=Configuracion_Asesores%2Fasesor%20eq%20%27ACORREA%27",
    // JCANTERO: "https://app.powerbi.com/reportEmbed?reportId=be9f6255-0b40-42f7-90d7-a43547adfc2a&autoAuth=true&ctid=42924fb7-263c-4656-a029-09b26634a3cd&filter=Configuracion_Asesores%2Fasesor%20eq%20%27JCANTERO%27",
    // SDELAROSA: "https://app.powerbi.com/reportEmbed?reportId=be9f6255-0b40-42f7-90d7-a43547adfc2a&autoAuth=true&ctid=42924fb7-263c-4656-a029-09b26634a3cd&filter=Configuracion_Asesores%2Fasesor%20eq%20%27SDELAROSA%27",
    // SJANNIN: "https://app.powerbi.com/reportEmbed?reportId=be9f6255-0b40-42f7-90d7-a43547adfc2a&autoAuth=true&ctid=42924fb7-263c-4656-a029-09b26634a3cd&filter=Configuracion_Asesores%2Fasesor%20eq%20%27SJANNIN%27",
    // YPENAD: "https://app.powerbi.com/reportEmbed?reportId=be9f6255-0b40-42f7-90d7-a43547adfc2a&autoAuth=true&ctid=42924fb7-263c-4656-a029-09b26634a3cd&filter=Configuracion_Asesores%2Fasesor%20eq%20%27YPENAD%27",
    // LEMONTERO: "https://app.powerbi.com/reportEmbed?reportId=be9f6255-0b40-42f7-90d7-a43547adfc2a&autoAuth=true&ctid=42924fb7-263c-4656-a029-09b26634a3cd&filter=Configuracion_Asesores%2Fasesor%20eq%20%27LEMONTERO%27",
    // RODRIGUEZI: "https://app.powerbi.com/reportEmbed?reportId=be9f6255-0b40-42f7-90d7-a43547adfc2a&autoAuth=true&ctid=42924fb7-263c-4656-a029-09b26634a3cd&filter=Configuracion_Asesores%2Fasesor%20eq%20%27RODRIGUEZI%27",
    // ACANTILLOE: "https://app.powerbi.com/reportEmbed?reportId=be9f6255-0b40-42f7-90d7-a43547adfc2a&autoAuth=true&ctid=42924fb7-263c-4656-a029-09b26634a3cd&filter=Configuracion_Asesores%2Fasesor%20eq%20%27ACANTILLOE%27",
    // ADMENDOZA: "https://app.powerbi.com/reportEmbed?reportId=be9f6255-0b40-42f7-90d7-a43547adfc2a&autoAuth=true&ctid=42924fb7-263c-4656-a029-09b26634a3cd&filter=Configuracion_Asesores%2Fasesor%20eq%20%27ADMENDOZA%27",
    // DGOMEZS: "https://app.powerbi.com/reportEmbed?reportId=be9f6255-0b40-42f7-90d7-a43547adfc2a&autoAuth=true&ctid=42924fb7-263c-4656-a029-09b26634a3cd&filter=Configuracion_Asesores%2Fasesor%20eq%20%27DGOMEZS%27",
    // JVALENCIA: "https://app.powerbi.com/reportEmbed?reportId=be9f6255-0b40-42f7-90d7-a43547adfc2a&autoAuth=true&ctid=42924fb7-263c-4656-a029-09b26634a3cd&filter=Configuracion_Asesores%2Fasesor%20eq%20%27JVALENCIA%27",
    // MCANARETE: "https://app.powerbi.com/reportEmbed?reportId=be9f6255-0b40-42f7-90d7-a43547adfc2a&autoAuth=true&ctid=42924fb7-263c-4656-a029-09b26634a3cd&filter=Configuracion_Asesores%2Fasesor%20eq%20%27MCANARETE%27",
    // YDURANGO: "https://app.powerbi.com/reportEmbed?reportId=be9f6255-0b40-42f7-90d7-a43547adfc2a&autoAuth=true&ctid=42924fb7-263c-4656-a029-09b26634a3cd&filter=Configuracion_Asesores%2Fasesor%20eq%20%27YDURANGO%27",
    // LMARTINEZ: "https://app.powerbi.com/reportEmbed?reportId=be9f6255-0b40-42f7-90d7-a43547adfc2a&autoAuth=true&ctid=42924fb7-263c-4656-a029-09b26634a3cd&filter=Configuracion_Asesores%2Fasesor%20eq%20%27LMARTINEZ%27",
    // KMOSORIO: "https://app.powerbi.com/reportEmbed?reportId=be9f6255-0b40-42f7-90d7-a43547adfc2a&autoAuth=true&ctid=42924fb7-263c-4656-a029-09b26634a3cd&filter=Configuracion_Asesores%2Fasesor%20eq%20%27KMOSORIO%27",
    // EYANCES: "https://app.powerbi.com/reportEmbed?reportId=be9f6255-0b40-42f7-90d7-a43547adfc2a&autoAuth=true&ctid=42924fb7-263c-4656-a029-09b26634a3cd&filter=Configuracion_Asesores%2Fasesor%20eq%20%27EYANCES%27",
    // ARJIMENEZ: "https://app.powerbi.com/reportEmbed?reportId=be9f6255-0b40-42f7-90d7-a43547adfc2a&autoAuth=true&ctid=42924fb7-263c-4656-a029-09b26634a3cd&filter=Configuracion_Asesores%2Fasesor%20eq%20%27ARJIMENEZ%27",
    // BCARABALLO: "https://app.powerbi.com/reportEmbed?reportId=be9f6255-0b40-42f7-90d7-a43547adfc2a&autoAuth=true&ctid=42924fb7-263c-4656-a029-09b26634a3cd&filter=Configuracion_Asesores%2Fasesor%20eq%20%27BCARABALLO%27",
    // DAYLOP: "https://app.powerbi.com/reportEmbed?reportId=be9f6255-0b40-42f7-90d7-a43547adfc2a&autoAuth=true&ctid=42924fb7-263c-4656-a029-09b26634a3cd&filter=Configuracion_Asesores%2Fasesor%20eq%20%27DAYLOP%27",
    // JGAMERO: "https://app.powerbi.com/reportEmbed?reportId=be9f6255-0b40-42f7-90d7-a43547adfc2a&autoAuth=true&ctid=42924fb7-263c-4656-a029-09b26634a3cd&filter=Configuracion_Asesores%2Fasesor%20eq%20%27JGAMERO%27",
    // LGARAY: "https://app.powerbi.com/reportEmbed?reportId=be9f6255-0b40-42f7-90d7-a43547adfc2a&autoAuth=true&ctid=42924fb7-263c-4656-a029-09b26634a3cd&filter=Configuracion_Asesores%2Fasesor%20eq%20%27LGARAY%27",
    // DAGOMEZ: "https://app.powerbi.com/reportEmbed?reportId=be9f6255-0b40-42f7-90d7-a43547adfc2a&autoAuth=true&ctid=42924fb7-263c-4656-a029-09b26634a3cd&filter=Configuracion_Asesores%2Fasesor%20eq%20%27DAGOMEZ%27",
    // EARROYO: "https://app.powerbi.com/reportEmbed?reportId=be9f6255-0b40-42f7-90d7-a43547adfc2a&autoAuth=true&ctid=42924fb7-263c-4656-a029-09b26634a3cd&filter=Configuracion_Asesores%2Fasesor%20eq%20%27EARROYO%27",
    // MICROCREDITO_ATL: "https://app.powerbi.com/reportEmbed?reportId=b52937b0-fde1-4559-9364-ecc308bf4da3&autoAuth=true&ctid=42924fb7-263c-4656-a029-09b26634a3cd&filter=Recaudo_Cartera%2Flinea_agencia%20eq%20%27MICROCREDITO_ATL%27",
    // MICROCREDITO_COR: "https://app.powerbi.com/reportEmbed?reportId=b52937b0-fde1-4559-9364-ecc308bf4da3&autoAuth=true&ctid=42924fb7-263c-4656-a029-09b26634a3cd&filter=Recaudo_Cartera%2Flinea_agencia%20eq%20%27MICROCREDITO_COR%27",
    // MICROCREDITO_SUC: "https://app.powerbi.com/reportEmbed?reportId=b52937b0-fde1-4559-9364-ecc308bf4da3&autoAuth=true&ctid=42924fb7-263c-4656-a029-09b26634a3cd&filter=Recaudo_Cartera%2Flinea_agencia%20eq%20%27MICROCREDITO_SUC%27",
    // JSAEZ: "https://app.powerbi.com/reportEmbed?reportId=be9f6255-0b40-42f7-90d7-a43547adfc2a&autoAuth=true&ctid=42924fb7-263c-4656-a029-09b26634a3cd&filter=Configuracion_Asesores%2Fasesor%20eq%20%27JSAEZ%27",
    // HLOPEZ: 'https://app.powerbi.com/reportEmbed?reportId=be9f6255-0b40-42f7-90d7-a43547adfc2a&autoAuth=true&ctid=42924fb7-263c-4656-a029-09b26634a3cd&filter=Configuracion_Asesores%2Fasesor%20eq%20%27HLOPEZ%27',
    // JCARRASCAL: 'https://app.powerbi.com/reportEmbed?reportId=be9f6255-0b40-42f7-90d7-a43547adfc2a&autoAuth=true&ctid=42924fb7-263c-4656-a029-09b26634a3cd&filter=Configuracion_Asesores%2Fasesor%20eq%20%27JCARRASCAL%27',
    // KBOHORQUEZ: 'https://app.powerbi.com/reportEmbed?reportId=be9f6255-0b40-42f7-90d7-a43547adfc2a&autoAuth=true&ctid=42924fb7-263c-4656-a029-09b26634a3cd&filter=Configuracion_Asesores%2Fasesor%20eq%20%27KBOHORQUEZ%27',
    // GUMORALES: 'https://app.powerbi.com/reportEmbed?reportId=be9f6255-0b40-42f7-90d7-a43547adfc2a&autoAuth=true&ctid=42924fb7-263c-4656-a029-09b26634a3cd&filter=Configuracion_Asesores%2Fasesor%20eq%20%27GUMORALES%27',
    // MAREALES:'https://app.powerbi.com/reportEmbed?reportId=be9f6255-0b40-42f7-90d7-a43547adfc2a&autoAuth=true&ctid=42924fb7-263c-4656-a029-09b26634a3cd&filter=Configuracion_Asesores%2Fasesor%20eq%20%27MAREALES%27',
    // JOQUENDO:'https://app.powerbi.com/reportEmbed?reportId=be9f6255-0b40-42f7-90d7-a43547adfc2a&autoAuth=true&ctid=42924fb7-263c-4656-a029-09b26634a3cd&filter=Configuracion_Asesores%2Fasesor%20eq%20%27JOQUENDO%27',
    // YGALEANO:'https://app.powerbi.com/reportEmbed?reportId=be9f6255-0b40-42f7-90d7-a43547adfc2a&autoAuth=true&ctid=42924fb7-263c-4656-a029-09b26634a3cd&filter=Configuracion_Asesores%2Fasesor%20eq%20%27YGALEANO%27',
    // AJSIERRA: 'https://app.powerbi.com/reportEmbed?reportId=be9f6255-0b40-42f7-90d7-a43547adfc2a&autoAuth=true&ctid=42924fb7-263c-4656-a029-09b26634a3cd&filter=Configuracion_Asesores%2Fasesor%20eq%20%27AJSIERRA%27',
    // BTETE:'https://app.powerbi.com/reportEmbed?reportId=be9f6255-0b40-42f7-90d7-a43547adfc2a&autoAuth=true&ctid=42924fb7-263c-4656-a029-09b26634a3cd&filter=Configuracion_Asesores%2Fasesor%20eq%20%27BTETE%27',
    // IROMAN : 'https://app.powerbi.com/reportEmbed?reportId=be9f6255-0b40-42f7-90d7-a43547adfc2a&autoAuth=true&ctid=42924fb7-263c-4656-a029-09b26634a3cd&filter=Configuracion_Asesores%2Fasesor%20eq%20%27IROMAN%27',
    // OCHIMA : 'https://app.powerbi.com/reportEmbed?reportId=be9f6255-0b40-42f7-90d7-a43547adfc2a&autoAuth=true&ctid=42924fb7-263c-4656-a029-09b26634a3cd&filter=Configuracion_Asesores%2Fasesor%20eq%20%27OCHIMA%27',
    // LFARAH : 'https://app.powerbi.com/reportEmbed?reportId=be9f6255-0b40-42f7-90d7-a43547adfc2a&autoAuth=true&ctid=42924fb7-263c-4656-a029-09b26634a3cd&filter=Configuracion_Asesores%2Fasesor%20eq%20%27LFARAH%27',
    // IGAMARRA : 'https://app.powerbi.com/reportEmbed?reportId=be9f6255-0b40-42f7-90d7-a43547adfc2a&autoAuth=true&ctid=42924fb7-263c-4656-a029-09b26634a3cd&filter=Configuracion_Asesores%2Fasesor%20eq%20%27IGAMARRA%27',
    // MCHERNANDE : 'https://app.powerbi.com/reportEmbed?reportId=be9f6255-0b40-42f7-90d7-a43547adfc2a&autoAuth=true&ctid=42924fb7-263c-4656-a029-09b26634a3cd&filter=Configuracion_Asesores%2Fasesor%20eq%20%27MCHERNANDE%27',
  },
  apiUrl: "https://prometheus.fintra.co:8443",
  manifest: {
    "name": "Fintra",
    "short_name": "Fintra",
    "theme_color": "#00B4DA",
    "background_color": "#ffffff",
    "display": "standalone",
    "orientation": "landscape",
    "Scope": "/",
    "start_url": "/",
    "icons": [
      {
        "src": "assets/images/icons/icon-72x72.png",
        "sizes": "72x72",
        "type": "image/png"
      },
      {
        "src": "assets/images/icons/icon-96x96.png",
        "sizes": "96x96",
        "type": "image/png"
      },
      {
        "src": "assets/images/icons/icon-128x128.png",
        "sizes": "128x128",
        "type": "image/png"
      },
      {
        "src": "assets/images/icons/icon-144x144.png",
        "sizes": "144x144",
        "type": "image/png"
      },
      {
        "src": "assets/images/icons/icon-152x152.png",
        "sizes": "152x152",
        "type": "image/png"
      },
      {
        "src": "assets/images/icons/icon-192x192.png",
        "sizes": "192x192",
        "type": "image/png"
      },
      {
        "src": "assets/images/icons/icon-384x384.png",
        "sizes": "384x384",
        "type": "image/png"
      },
      {
        "src": "assets/images/icons/icon-512x512.png",
        "sizes": "512x512",
        "type": "image/png"
      }
    ],
    "splash_pages": null
  }
};
