import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';


@Injectable({
  providedIn: 'root'
})

export class AsesorServiceService {


public environmentUrl = environment.apiUrl

  constructor( private _http: HttpClient) { }

  public obtenerUrlAsesor(user: string): Observable<any[]> {
    const mode = this.modoVisualizacion();
    const api = 'api-fintra/api/generic/qry/tk/link-bi-asesor-micro'
    return this._http.get<any[]>(`${this.environmentUrl}/${api}/${mode}/${user}`)
  }

  private modoVisualizacion(): number {
    return window.innerWidth >600 ? 2 : 1
  }

  public startLoading({ title = 'Cargando asesor', html = 'Por favor espere' }): void {

    Swal.fire({ title, html, allowOutsideClick: false, timer: 500000, didOpen: () => { Swal.showLoading() }, })

  }

  public stopLoading(): void {
    Swal.close();
  }

  public alertError(param:any={}): void {


    Swal.fire({
      allowOutsideClick:  false,
      backdrop: true,
      title:  'Error!',
      text: param.text || "Su solicitud no pudo ser procesada, por favor intente nuevamente",
      icon : 'error',
      customClass: {
        confirmButton: 'rounded-full w-20 bg-gray-400 ring-0'
      }
    })
  }




}
