import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from "@angular/router";
import { environment } from '../../environments/environment';
import { AsesorServiceService } from 'src/services/asesor-service.service';
import { takeUntil } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, OnDestroy {

  public url :SafeResourceUrl = '';
  public user = this.route.snapshot.paramMap.get('user');
  public unsuscribe$: Subject<void> = new Subject<void>();
  public loadBi:boolean = false

  constructor(
    private sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private _asesorService: AsesorServiceService,
    ) {


    // if ((this.user != "null") && (this.user != "undefined") && (this.user != undefined)) {
    //   let urlCompleta = `https://app.powerbi.com/reportEmbed?reportId=be9f6255-0b40-42f7-90d7-a43547adfc2a&autoAuth=true&ctid=42924fb7-263c-4656-a029-09b26634a3cd&${this.user.toUpperCase()}%27`
    //   console.log(urlCompleta)
    //   this.url = this.transform(urlCompleta)

    //   this.setManifest()
    // }
  }
  ngOnDestroy(): void {
    this.unsuscribe$.unsubscribe();
  }

  ngOnInit() {
    this.cargarUrlUsuario();
  }


  transform(url) {

    return this.sanitizer.bypassSecurityTrustResourceUrl(url);

  }

  setManifest() {
    var myDynamicManifest = environment.manifest;
    myDynamicManifest.start_url = "/" + this.user;

    const stringManifest = JSON.stringify(myDynamicManifest);
    const blob = new Blob([stringManifest], { type: 'application/json' });
    const manifestURL = URL.createObjectURL(blob);
    document.querySelector('#my-manifest-placeholder').setAttribute('href', manifestURL);
  }

  public cargarUrlUsuario(): void {

    this._asesorService.startLoading({});

    const asesor = this.user.trim().toUpperCase();

    this._asesorService.obtenerUrlAsesor(asesor).pipe(takeUntil(this.unsuscribe$)).subscribe({
      next:(resp : any)=>{

        if(resp.data[0].url==='No existe ese usuario'){
          const param= {text:'Lo sentimos, usuario no encontrado'}
          this._asesorService.alertError(param);
          this.loadBi = false
          return
        }

        if(resp.data[0].url.includes('https:')){
          this.loadBi = true
          this.url = this.transform(resp.data[0].url);
          this._asesorService.stopLoading();
        }

        if(resp.data.length===0){
          this.loadBi = false
          this._asesorService.stopLoading();
        }

      },

      error:(e)=>{
        this._asesorService.alertError();
      }
    })
  }

}
